import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import queryString from "query-string";
import { Formik, useFormikContext } from "formik";
import { navigate } from "gatsby";
import styled from "styled-components";
import ContentBox from "../components/ContentBox";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Label from "../components/Label";
import Input from "../components/Input";
import Button from "../components/buttons/Button";
import Link from "../components/Link";
import ActionBar from "../components/OldActionBar";
import DangerButton from "../components/buttons/DangerButton";
import Modal from "../components/Modal";
import FormError from "../components/FormError";
import PageWrapper from "../components/PageWrapper";
import DarkPageHeading from "../components/DarkPageHeading";
import UserSelect from "../components/UserSelect";

const GET_EVENT_GROUP = gql`
  query GetEventGroup($eventId: ID!, $number: Int!) {
    eventGroup: getEventGroup(eventId: $eventId, number: $number) {
      id
      number
      name
      hostUser {
        id
        username
      }
    }
  }
`;

const SAVE_EVENT_GROUP = gql`
  mutation SaveEventGroup(
    $eventId: ID!
    $name: String!
    $hostUserId: ID!
    $number: Int
  ) {
    saveEventGroup(
      eventId: $eventId
      number: $number
      name: $name
      hostUserId: $hostUserId
    ) {
      eventGroup {
        id
        number
        name
      }
    }
  }
`;

const DELETE_EVENT_GROUP = gql`
  mutation DeleteEventGroup($eventId: ID!, $number: Int!) {
    deleteEventGroup(eventId: $eventId, number: $number) {
      success
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const EventStartedMessage = styled.div`
  font-size: 18px;
  color: red;
  margin-bottom: 20px;
`;

const GroupEditor = ({
  eventId,
  number,
  hostUserId,
  hostUsername,
  originalValues,
  isStarted,
  onDelete,
}) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    submitForm,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [isDeleting, setIsDeleting] = useState();

  const isChanged = () =>
    values.name !== originalValues.name ||
    !originalValues.hostUser ||
    values.hostUserId !== originalValues.hostUser.id;

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    await onDelete();
  };

  return (
    <ContentBox direction="column">
      <DarkPageHeading>
        {!number ? "Create Event Group" : "Edit Event Group"}
      </DarkPageHeading>
      <FormWrapper>
        <Form>
          {isStarted && (
            <EventStartedMessage>
              Event Group is not editable since event has already started.
            </EventStartedMessage>
          )}

          <FormField>
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              name="name"
              autoComplete="off"
              disabled={isStarted}
              value={values.name}
              onChange={handleChange}
            />
            {errors.name && touched.name && (
              <FormError>{errors.name}</FormError>
            )}
          </FormField>
          <FormField>
            <Label>Host</Label>
            <UserSelect
              name="hostUserId"
              defaultValue={{ value: hostUserId, label: hostUsername }}
              onChange={(option) => setFieldValue("hostUserId", option.value)}
            />
            {errors.hostUserId && touched.hostUserId && (
              <FormError>{errors.hostUserId}</FormError>
            )}
          </FormField>
        </Form>
      </FormWrapper>
      <ActionBar>
        <Button
          size="large"
          disabled={!isChanged() || isSubmitting}
          onClick={() => submitForm()}
        >
          {isSubmitting ? "Saving" : "Save Group"}
        </Button>
        <Link to={`/create-edit-event?eventId=${eventId}`}>
          <Button size="large">Close</Button>
        </Link>
        <DangerButton
          size="large"
          disabled={!number || isStarted}
          onClick={handleDeleteClick}
        >
          Delete Group
        </DangerButton>
      </ActionBar>
      {showDeleteModal && (
        <Modal
          title="Delete Event Group"
          text="Delete this event group?"
          buttons={
            <>
              <DangerButton
                size="large"
                disabled={isDeleting}
                onClick={handleConfirmDelete}
              >
                {isDeleting ? "Deleting" : "Delete"}
              </DangerButton>
              <Button size="large" onClick={handleCancelDelete}>
                Cancel
              </Button>
            </>
          }
        />
      )}
    </ContentBox>
  );
};

export default function CreateEditEventGroup({ location }) {
  const { eventId, number, nextNumber } = queryString.parse(location.search);

  const { data } = useQuery(GET_EVENT_GROUP, {
    variables: {
      eventId: eventId,
      number: number,
    },
    skip: !number,
  });

  const [saveEventGroup] = useMutation(SAVE_EVENT_GROUP);
  const [deleteEventGroup] = useMutation(DELETE_EVENT_GROUP);

  if (number && !data) {
    return null;
  }

  const originalValues = (data && data.eventGroup) || {};

  const eventGroup = data && data.eventGroup;
  const hostUserId =
    eventGroup && eventGroup.hostUser && eventGroup.hostUser.id;
  const hostUsername =
    eventGroup && eventGroup.hostUser && eventGroup.hostUser.username;

  const initialValues = {
    name: nextNumber
      ? `Group ${nextNumber}`
      : data && data.eventGroup && data.eventGroup.name,
    hostUserId:
      data &&
      data.eventGroup &&
      data.eventGroup.hostUser &&
      data.eventGroup.hostUser.id,
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "You must enter a name";
    }
    if (!values.hostUserId) {
      errors.hostUserId = "You must choose a host";
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    await saveEventGroup({
      variables: {
        eventId: eventId,
        number: number,
        name: values.name,
        hostUserId: values.hostUserId,
      },
    });
    await navigate(`/create-edit-event?eventId=${eventId}`);
  };

  const handleDelete = async () => {
    await deleteEventGroup({
      variables: {
        eventId: eventId,
        number: number,
      },
    });

    await navigate(`/create-edit-event?eventId=${eventId}`);
  };

  return (
    <PageWrapper>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <GroupEditor
          eventId={eventId}
          number={number}
          hostUserId={hostUserId}
          hostUsername={hostUsername}
          originalValues={originalValues}
          isStarted={
            data && data.group && data.group.event && data.group.event.started
          }
          onDelete={handleDelete}
        />
      </Formik>
    </PageWrapper>
  );
}
